<template>
  <PageLayout :background="'color'">
    <div id="recaptcha-container" />
    <IonGrid class="verify-phone-number">
      <IonRow class="ion-justify-content-center">
        <IonCol size="auto">
          <img width="170" alt="logo" src="/assets/sky_logo.svg" />
        </IonCol>
      </IonRow>

      <IonRow class="pt-3 pb-3">
        <IonCol>
          <div
              class="policy-text"
              v-for="(text, i) in $t(
              'login.verify_phone_number_then_accept_policy_and_terms'
            ).split('\n')"
              :key="i"
          >
            {{ text }}
          </div>
        </IonCol>
      </IonRow>

      <IonRow style="padding-bottom: 20px">
        <IonCol class="pa-0">
          <BaseField
              v-model="credential.username"
              required
          >
          </BaseField>
        </IonCol>
      </IonRow>
      <IonRow style="padding-bottom: 20px">
        <IonCol class="pa-0">
          <BaseField
              v-model="credential.password"
              type="password"
              required
          >
          </BaseField>
        </IonCol>
      </IonRow>

      <IonRow class="ion-align-items-center" style="padding-bottom: 20px">
        <IonCol size="auto">
          <IonCol>
            <IonCheckbox
                id="cb-00"
                class="policy-checkbox"
                v-model="termsAndPolicyAccepted"
            />
          </IonCol>
          <IonCol>
            <span class="policy-link">
              {{ $t('login.accept') }}
              <a @click="handleOpenPolicyModal">"{{ $t('login.privacy_policy') }}"</a>
              {{ $t('login.and') }}
            </span>
            <br />
            <span class="policy-link">
              <a @click="handleOpenTermsModal">
                "{{ $t('login.terms_and_conditions') }}"
              </a>
            </span>
          </IonCol>
        </IonCol>
      </IonRow>

      <IonRow class="ion-justify-content-center">
        <IonCol size="12" style="text-align: center">
          <IonButton
              class="btn-gradient send-otp-button"
              :disabled="credential.password == '' || credential.username == '' || !termsAndPolicyAccepted"
              @click="login()"
          >
            {{ $t('login.label.login') }}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
    <LogInErrorModal v-if="showErrorModal" v-on:close="showErrorModal = false" />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonButton,
  IonGrid,
  IonCheckbox,
  isPlatform
} from '@ionic/vue'
import firebase from 'firebase/app'
import PageLayout from '@/components/Layout/PageLayout.vue'
import LogInErrorModal from '@/modules/authentication/LogInErrorModal.vue'
import { FirebaseX } from '@ionic-native/firebase-x'
import BaseField from '@/components/fields/BaseField.vue'
import {getLogInDevice} from "@/utils/deviceUuid";

@Component({
  components: {
    BaseField,
    PageLayout,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
    IonGrid,
    IonCheckbox,
    LogInErrorModal
  }
})
export default class LoginCustom extends Vue {
  termsAndPolicyAccepted = false
  credential = {
    username:'',
    password:''
  }
  showErrorModal = false

  get policyModal() {
    return this.$store.state.modal.policy
  }

  get termsModal() {
    return this.$store.state.modal.terms
  }

  handleOpenPolicyModal() {
    this.policyModal.forceRead = false
    this.policyModal.isOpen = true
  }

  handleOpenTermsModal() {
    this.termsModal.forceRead = false
    this.termsModal.isOpen = true
  }

  acceptPolicyAndTerms() {
    const { uuid, source } = getLogInDevice()
    const params = { deviceId: uuid, deviceSource: source }

    this.$http
        .post('/policy/latest/accept', params)
        .then(() => this.$router.push('/home'))
        .catch(console.log)
  }

  async login() {
    console.log('Login clicked')
    try {
      await this.$http.post('/firebase/login', this.credential).then(res=>{
        // if(res.data.status == 200){
          firebase.auth().signInWithCustomToken(res.data.token).then((userCredential) => {
            this.acceptPolicyAndTerms()
          }).catch((error) => {
            console.log("errr",error)
            this.showErrorModal = true
          });

      })
    } catch (e) {
      console.log(e)
      alert(e)
      this.showErrorModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.send-otp-button {
  width: 200px;
  height: 43px;
  font-size: 16px;
}
.input-options {
  z-index: 1;
  height: 43px;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  border: 0.5px solid #c6c6c6;
  opacity: 1;
}
.verify-phone-number {
  padding: 96px 15px 0 15px;
  .policy-text {
    color: #333333;
    font-size: 12px;
    letter-spacing: normal;
    text-align: center;
  }
  .policy-link {
    font-size: 14px;
  }
}
</style>

<style lang="scss">
ion-checkbox {
  &.policy-checkbox {
    margin-bottom: -4px;
    margin-right: 8px;
    background: #ffffff;
    border-width: 1px !important;
    border-style: solid !important;
    border-radius: 4px;
    border-color: #bbbbbb !important;
    --height: 35px;
    --width: 35px;
    --size: 17px;
    --checkmark-color: #01b3bf;
    --checkmark-width: 4px;
    --background-checked: #ffffff;
    --border-color: #ffffff;
    --border-color-checked: #ffffff;
  }
}
.vti__selection {
  border-right: 1px solid #c6c6c6;
  padding-right: 10px;
}
.vti__dropdown-list {
  border: none !important;
}
.input-options {
  & > input {
    border-radius: 10px !important;
  }
}
</style>
