<template>
  <PageLayout :style="{ paddingBottom: '70px' }">
    <PageHeader :title="pageHeader" :withBackButton="true" />
    <PdfViewer v-if="fileUrl" :fileUrl="fileUrl" :fileName="fileName" />
  </PageLayout>
</template>

<script lang="ts">
import { Options as Component, Vue } from 'vue-class-component'
import { PageLayout, PageHeader } from '@/components/Layout'
import PdfViewer from '@/components/PdfViewer.vue'
import { BASE_API } from '@/constants'

@Component({
  components: { PageLayout, PdfViewer, PageHeader },
  props: []
})
export default class BillPdf extends Vue {
  invoice = {} as any

  get pageHeader() {
    return this.$t(`bill.label.download_${this.$route.query.type}`)
  }

  get fileUrl(): string {
    if (this.invoice?._id) {
      return `${BASE_API}/invoice/${this.$route.params.id}/${this.$route.query.type}/ke?type=png`
    }
    return ''
  }

  get fileName(): string {
    return `${this.$route.query.type}_${this.invoice?.number}`
  }

  mounted() {
    this.$http.get(`/invoice/` + this.$route.params.id).then(res => {
      this.invoice = res?.data
    })
  }
}
</script>

<style lang="scss"></style>
