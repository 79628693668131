
import { Options as Component, Vue } from 'vue-class-component'
import {
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonButton,
  IonGrid,
  IonCheckbox,
  isPlatform
} from '@ionic/vue'
import firebase from 'firebase/app'
import PageLayout from '@/components/Layout/PageLayout.vue'
import LogInErrorModal from '@/modules/authentication/LogInErrorModal.vue'
import { FirebaseX } from '@ionic-native/firebase-x'
import BaseField from '@/components/fields/BaseField.vue'
import {getLogInDevice} from "@/utils/deviceUuid";

@Component({
  components: {
    BaseField,
    PageLayout,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
    IonGrid,
    IonCheckbox,
    LogInErrorModal
  }
})
export default class LoginCustom extends Vue {
  termsAndPolicyAccepted = false
  credential = {
    username:'',
    password:''
  }
  showErrorModal = false

  get policyModal() {
    return this.$store.state.modal.policy
  }

  get termsModal() {
    return this.$store.state.modal.terms
  }

  handleOpenPolicyModal() {
    this.policyModal.forceRead = false
    this.policyModal.isOpen = true
  }

  handleOpenTermsModal() {
    this.termsModal.forceRead = false
    this.termsModal.isOpen = true
  }

  acceptPolicyAndTerms() {
    const { uuid, source } = getLogInDevice()
    const params = { deviceId: uuid, deviceSource: source }

    this.$http
        .post('/policy/latest/accept', params)
        .then(() => this.$router.push('/home'))
        .catch(console.log)
  }

  async login() {
    console.log('Login clicked')
    try {
      await this.$http.post('/firebase/login', this.credential).then(res=>{
        // if(res.data.status == 200){
          firebase.auth().signInWithCustomToken(res.data.token).then((userCredential) => {
            this.acceptPolicyAndTerms()
          }).catch((error) => {
            console.log("errr",error)
            this.showErrorModal = true
          });

      })
    } catch (e) {
      console.log(e)
      alert(e)
      this.showErrorModal = true
    }
  }
}
