import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import store from '../store'

import Landing from '@/views/authentication/Landing.vue'
import Login from '@/views/authentication/Login.vue'
import VerifyOtp from '@/views/authentication/VerifyOtp.vue'

import BillList from '@/views/bills/BillList.vue'
import BillShow from '@/views/bills/BillShow.vue'
import BillGroupList from '@/views/bills/BillGroupList.vue'
import BillGroupShow from '@/views/bills/BillGroupShow.vue'
import BillPdf from '@/views/bills/BillPdf.vue'
import BillGroupPdf from '@/views/bills/BillGroupPdf.vue'
import BillHistory from '@/views/bills/BillHistory.vue'

import Home from '@/views/Home.vue'
import MyProjects from '@/views/MyProjects.vue'
import Posts from '@/views/Posts.vue'
import Services from '@/views/Services.vue'
import ServicesShow from '@/views/ServicesShow.vue'
import Visitors from '@/views/Visitors.vue'
import Forms from '@/views/Forms.vue'
import AnnouncementHomePage from '@/views/announcements/index.vue'
import NewsHomePage from '@/views/news/index.vue'
import NewsShowPage from '@/views/news/show.vue'
import Settings from '@/views/setting/Setting.vue'
import ChangeLanguage from '@/views/setting/ChangeLanguage.vue'
import BottomNavigationMenu from '@/views/BottomNavigationMenu.vue'
import AnnouncementsShow from '@/views/announcements/show.vue'
import Maintenance from '@/views/Maintenance.vue'
import http from '../http'
import { login } from '@/utils/firebase'

import axios from '@/http-no-loading'
import OSS from 'ali-oss'
import Profile from '@/views/Profile.vue'
import ImagePicker from '@/components/profile/SelectImage.vue'
import LoginCustom from "@/views/authentication/LoginCustom.vue";

const routes: Array<RouteRecordRaw> = [
  // before login
  {
    path: '/landing',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login_custom',
    name: 'Login Password ',
    component: LoginCustom
  },
  {
    path: '/verify_otp',
    name: 'VerifyOtp',
    component: VerifyOtp
  },
  //after login
  {
    path: '/',
    component: BottomNavigationMenu,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        component: Home
      },
      {
        path: 'settings',
        component: Settings
      },
      {
        path: 'settings/change_language',
        component: ChangeLanguage
      }
    ]
  },
  {
    path: '/my_projects',
    name: 'My Projects',
    component: MyProjects,
    props: true,
    children: []
  },
  {
    path: '/bills',
    name: 'Bills Index',
    component: BillList,
    props: true,
    children: []
  },
  {
    path: '/bills/:id',
    name: 'Bills Show',
    component: BillShow
  },
  {
    path: '/bill_groups',
    name: 'Bill Groups Index',
    component: BillGroupList,
    props: true,
    children: []
  },
  {
    path: '/bill_groups/:id',
    name: 'Bill Groups Show',
    component: BillGroupShow
  },
  {
    path: '/bills/:id/pdf',
    name: 'Bill Pdf',
    component: BillPdf
  },
  {
    path: '/bill_groups/:id/pdf',
    name: 'Bill Group Pdf',
    component: BillGroupPdf
  },
  {
    path: '/bills_history',
    name: 'Bill History',
    component: BillHistory
  },
  {
    path: '/news',
    name: 'News',
    component: NewsHomePage
  },
  {
    path: '/news/:id',
    name: 'News Show',
    component: NewsShowPage
  },
  {
    path: '/posts',
    name: 'Posts',
    component: Posts
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: AnnouncementHomePage
  },
  {
    path: '/announcements/:id',
    name: 'Announcement Show',
    component: AnnouncementsShow
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/services/:name',
    name: 'Service Show',
    component: ServicesShow
  },
  {
    path: '/visitors',
    name: 'Visitors',
    component: Visitors
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/user',
    name: 'Profile',
    component: Profile
  },
  //TODO remove this and component when have the right form
  {
    path: '/forms',
    name: 'forms',
    component: Forms
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const fetchPolicyAndTerms = async () => {
  http.get('policy/unaccepted').then(res => {
    const { privacyAndPolicy, termAndCondition } = res.data
    if (privacyAndPolicy) {
      const policyModal = store.state.modal.policy
      policyModal.isOpen = true
      policyModal.forceRead = true
    }
    if (termAndCondition) {
      const termsModal = store.state.modal.terms
      termsModal.isOpen = true
      termsModal.forceRead = true
    }
  })
}
/***
 * NOW: fetchPolicyAndTerms run before every request
 * Possible improvement: each time new policy added: update to all user field isLates_xx = false
 * ==> Frontend check, if data from user.is_latest_xx = false, then make request
 */
router.beforeEach(async (to, from, next) => {
  await login()
    .then(res => {
      if (res) {
        fetchPolicyAndTerms()
        if (to.path === '/login' || to.path === '/verify_otp' || to.path === '/landing' || to.path === '/login_custom') {
          router.push('/home')
        }
        next()
      } else if (
        to.path !== '/login' &&
        to.path !== '/verify_otp' &&
        to.path !== '/landing'&&
        to.path !== '/login_custom'
      ) {
        router.push('/landing')
        next()
      } else {
        next()
      }
    })
    .catch(e => {
      console.log(e)
      next()
    })
})

export default router
